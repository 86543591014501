<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12.mb-2.title-box
      b-button(variant="secondary" to="/") 回上一層
    .col-lg-12
      b-card.mb-1(no-body)
        b-card-header(header-tag="header")
          .header-row
            .header-title.mb-0 基本設定
        b-card-body
          form.form-horizontal(role="form")
            .row
              .col-6

                .row.mb-2
                  .form-group.col-8.mb-0
                    label 項目標題
                    input.form-control(
                      type="text",
                      v-model="file.title"
                      placeholder="請輸入本次設定的用途名稱"
                    )

                .row.mb-2
                  .form-group.col-8.mb-0
                    label 選擇設定種類
                    .options
                      b-button.width-sm.mr-2(
                        @click="setFileType(1)"
                        :variant="( file.type == 1 ) ? 'dark' : 'default'"
                      ) 以檔案縮網址
                      b-button.width-sm.mr-2(
                        @click="setFileType(2)"
                        :variant="( file.type == 2 ) ? 'dark' : 'default'"
                      ) 以連結縮網址


                .row.mb-2(v-if="file.type == 2")
                  .form-group.col-8.mb-0
                    label 目標網址
                    input.form-control(
                      type="text",
                      v-model="file.target_uri"
                      placeholder="目標網址"
                    )

                .row.mb-4(v-if="file.type == 1")
                  .form-group.col-12.mb-0
                    label 目標檔案
                  .col-lg-7.row
                    .col(v-if="!file.content.url")
                      vue-dropzone(
                        id="dropzone-program", 
                        key="dropzone-program", 
                        ref="myVueDropzone", 
                        :use-custom-slot="true", 
                        :options="dropzoneOptions",
                        @vdropzone-success-multiple="uploadImageSuccess"
                      )
                        .dz-message.needsclick
                          i.h1.text-muted.ri-upload-cloud-2-line
                          h4 上傳檔案

                    .col(v-if="file.content.url")
                      .d-flex.align-items-end
                        .dropzone-previews
                          .card.mb-0.shadow-none
                            img.preview-img.rounded.bg-light(src='@/assets/images/file_icon.png' alt='')
                            a.img-del-btn.btn.btn-link.btn-lg.text-muted(@click="deleteImg()")
                              i.fe-x 

              .col-6
                .row.mb-2
                  .form-group.col-8
                    label 短網址
                    input.form-control(
                      type="text",
                      readonly
                      placeholder="系統產生的短網址"
                      :value="(file.customize_link) ? file.customize_link : file.sort_uri"
                    )
                  .form-group.col-8.mb-0(v-if="file.type == 1")
                    label 自定義連結顯示名稱
                      small  (僅限 PDF 檔案使用)
                    input.form-control(
                      type="text",
                      placeholder="請輸入檔案需要顯示的名稱"
                      v-model="file.customize_name"
                    )
                    span(v-if="file.customize_name && fileId !== 'create'") 注意: 如果修改此內容, 原先提供的連結將會失效 !

                .row.mb-2(v-if="file.sort_uri")
                  .form-group.col-auto.mb-0
                    label QR Code
                    .qrcode_box
                      qr-code(v-if="file.customize_link", :text='file.customize_link' :size='180' color='#000' bg-color='#fff' error-level='L')
                      qr-code(v-else, :text='file.sort_uri' :size='180' color='#000' bg-color='#fff' error-level='L')
            hr

            .row
              .col-6
                .row
                  .col-lg-12
                    .form-group.mb-3
                      label 上架日期
                      br
                      .row
                        .col-6
                          date-picker(
                            v-model="file.start_date"
                            format="YYYY-MM-DD"
                            value-type="format",
                            append-to-body,
                            lang="zh",
                            placeholder="請選擇公告起始日期"
                          )
                .row
                  .col-lg-12
                    .form-group.mb-3
                      label 下架日期
                      br
                      .row
                        .col-6
                          date-picker(
                            v-model="file.end_date"
                            format="YYYY-MM-DD"
                            value-type="format",
                            append-to-body,
                            lang="zh",
                            placeholder="請選擇公告下架日期"
                          )

            .mt-5
              b-button.width-lg.mr-2(v-if="fileId === 'create'" size="md" variant="primary" @click="createFileItem()")  新增
              b-button.width-lg.mr-2(v-else size="md" variant="primary" @click="updateFileItem()") 更新
</template>
<script>
import Layout from "../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Select2 from 'v-select2-component';
import vue2Dropzone from "vue2-dropzone";
/**
 * Starter component
 */
export default {
  head () {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data () {
    return {
      title: "檔案編輯",
      items: [
        {
          text: "檔案管理",
          href: "/",
        },
        {
          text: "檔案編輯",
          active: true,
        }
      ],
      dropzoneOptions: {
        url: process.env.VUE_APP_UPLOAD_SERVER_URI + "/media/create/upload",
        method: "post",
        uploadMultiple: true,
        paramName: "files",
        maxFilesize: 2048,
        headers: {
          "Authorization": 'Bearer ' + this.$root.auth_token,
        },
        previewTemplate: this.template(),
      },
      fileId: '',
      file: {
        type: 1,
        title: "",
        content: { url: "" },
        target_uri: "",
        sort_link: "",
        customize_link: "",
        start_date: null,
        end_date: null
      },
    }
  },
  computed: {

  },
  created () {
    // 獲得操作公告ID
    this.fileId = this.$route.params.fileId
    // 呼叫資料
    if (this.fileId !== 'create') {
      this.getFileItem()
    }
  },
  mounted () {

  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    Select2,
    vueDropzone: vue2Dropzone
  },
  watch: {

  },
  methods: {
    setFileType (_type) {
      this.file.type = _type
    },
    // 處理圖片下載模組
    template () {
      return ` <div class="dropzone-previews">
            <div class="card mb-0 shadow-none">
              <img data-dz-thumbnail src="#" class="preview-img avatar-sm rounded bg-light" alt="">
              <!-- Button -->
              <a href="" class="img-del-btn btn btn-link btn-lg text-muted" data-dz-remove>
                  <i class="fe-x"></i>
              </a>
            </div>
        </div>`;
    },
    // 獲得圖片資料
    getFileItem () {
      let vm = this
      this.$root.apis.getFileItem(this.fileId, function (_response) {
        let data = _response.body.data
        data.content = { url: data.target_uri }
        vm.file = data
      })
    },
    // 圖片上傳成功時
    uploadImageSuccess (file, response) {
      console.log("Upload Response:", response)
      this.file.content.url = response.data[0].url
      this.file.target_uri = response.data[0].url
      this.$root.common.showSingleNotify("檔案上傳成功！")
    },
    // 移除圖片項目
    deleteImg () {
      this.file.content.url = ''
    },
    // 建立檔案項目
    createFileItem () {
      let vm = this
      this.$root.apis.createFileItem(this.file, function (_response) {
        vm.$root.common.showSingleNotify("檔案建立成功！")
        vm.fileId = _response.body.data
        vm.getFileItem()
        vm.$router.push({ name: 'fileEdit', params: { fileId: _response.body.data } })
      }, function (_error) {
        vm.$root.common.showErrorNotify(_error.body.data)
      })
    },
    // 更新檔案項目
    updateFileItem () {
      let vm = this
      this.$root.apis.putFileItem(this.fileId, this.file, function (_response) {
        vm.$root.common.showSingleNotify("檔案更新成功！")
        vm.getFileItem()
      }, function (_error) {
        vm.$root.common.showErrorNotify(_error.body.data)
      })
    },
  }
};
</script>


<style lang="sass" scoped>
.card-header
  background: #fff
.qrcode_box
  width: 200px
  height: 200px
  border-radius: 5px
  border: 1px solid #ced4da
  padding: 10px

::v-deep .nav-tabs .nav-link
  width: 250px
  text-align: center
  line-height: 30px
::v-deep .vue-dropzone
  padding: 0
  width: 100%
  height: 100%
  max-height: 330px
  max-width: 320px

::v-deep .dropzone-previews
  width: 100%
  height: 100%
  max-height: 330px
  max-width: 320px

  .preview-img
    width: 100%
    height: 100%
    max-height: 330px
    max-width: 320px
    object-fit: cover

    
  .img-del-btn
    position: absolute
    right: -15px
    top: -15px
    background: #1e40b0
    border-radius: 50%
    padding: 0
    height: 30px
    width: 30px
    line-height: 30px
    cursor: pointer
    i
      color: #fff
      cursor: pointer
.program-text
  color: #181818
  font-size: 16px

</style>